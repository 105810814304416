angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/poker-stats.html',
    '<div ng-init="vm.init();">\n' +
    '	<div ng-cloak>\n' +
    '		<h5>\n' +
    '			Games report ( Between {{ vm.displayGames.fromDate | date:\'yyyy-MM-dd\' }} and {{ vm.displayGames.toDate | date:\'yyyy-MM-dd\' }} )\n' +
    '		</h5>\n' +
    '		<div class="row">\n' +
    '			<div class="col-md-12">\n' +
    '				<form name="statsForm" class="form-inline">\n' +
    '					<div class="form-group">\n' +
    '						<label for="fromDate">From</label>\n' +
    '						<input type="text" class="form-control" ng-change="vm.loadGames()" id="fromDate" ng-model="vm.displayGames.fromDate" data-date-format="yyyy-MM-dd" data-date-type="number" data-autoclose="1" data-use-native="true" data-icon-left="icon icon-arrow-left" data-icon-right="icon icon-arrow-right" name="fromDate" bs-datepicker>\n' +
    '					</div>\n' +
    '					<div class="form-group">\n' +
    '						<label for="toDate">To</label>\n' +
    '						<input type="text" class="form-control" ng-change="vm.loadGames()" id="toDate" ng-model="vm.displayGames.toDate" data-date-format="yyyy-MM-dd" data-date-type="number" data-autoclose="1" data-use-native="true" data-icon-left="icon icon-arrow-left" data-icon-right="icon icon-arrow-right" name="toDate" bs-datepicker>\n' +
    '					</div>\n' +
    '					<div class="btn-group">\n' +
    '						<button class="btn btn-info" ng-click="vm.loadLastGame()" analytics-on analytics-category="Actions">Last Game</button>\n' +
    '						<button class="btn btn-info" ng-click="vm.loadLastMonthGames()" analytics-on analytics-category="Actions">Last Month</button>\n' +
    '						<button class="btn btn-info" ng-click="vm.loadLastQuarterGames()" analytics-on analytics-category="Actions">Last Quarter</button>\n' +
    '						<button class="btn btn-info" ng-click="vm.loadLastYearGames()" analytics-on analytics-category="Actions">Last Year</button>\n' +
    '						<button class="btn btn-info" ng-click="vm.loadAllTimeGames()" analytics-on analytics-category="Actions">All Time</button>\n' +
    '					</div>\n' +
    '				</form>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="row">\n' +
    '			<div class="col-md-3">\n' +
    '				<strong>Total games: </strong><span class="badge">{{ vm.totalGames }}</span>\n' +
    '			</div>\n' +
    '			<div class="col-md-9">\n' +
    '				<em>Showing players who played over </em>\n' +
    '				<div class="btn-group btn-group-sm">\n' +
    '					<label class="btn btn-default" ng-repeat="option in vm.filterOptions.gamesCount" ng-model="vm.filter.gamesCount" uib-btn-radio="option"><span class="badge">{{ option | percentage }}</span> <em>({{ option * vm.totalGames | number:0 }})</em></label>\n' +
    '				</div>\n' +
    '				<em> of games</em>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row">\n' +
    '		<div class="col-md-12">\n' +
    '			<table class="table table-striped table-hover">\n' +
    '				<thead>\n' +
    '					<tr>\n' +
    '						<th>\n' +
    '							<span>\n' +
    '								Name\n' +
    '							</span>\n' +
    '							<span class="form-inline">\n' +
    '								<input class="form-control input-sm" ng-model="vm.filter.name" placeholder="Player\'s name or other property"/>\n' +
    '							</span>\n' +
    '						</th>\n' +
    '						<th class="hidden-sm hidden-xs">Buy in</th>\n' +
    '						<th class="hidden-sm hidden-xs">Buy out</th>\n' +
    '						<th>Total Profit</th>\n' +
    '						<th>Profit per game</th>\n' +
    '						<th>Avarage Buy in</th>\n' +
    '					</tr>\n' +
    '				</thead>\n' +
    '				<tbody>\n' +
    '					<tr ng-repeat="player in vm.displayGames.players | filter:vm.playerPredicate" ng-class="{\'success\': (player.buyout > player.buyin), \'danger\': (player.buyout < player.buyin)}">\n' +
    '						<td>\n' +
    '							<span>{{ player.displayName }}</span> <span class="badge" title="Games played">{{ player.gamesCount }}</span> <button class="btn btn-link" ng-click="vm.openPlayerDetailsDialog(player)" analytics-on analytics-event="Player Details" analytics-category="Actions" analytics-label="{{ player.displayName }}"><span class="icon icon-external-link"></span></button>\n' +
    '						</td>\n' +
    '						<td class="hidden-sm hidden-xs">{{ player.buyin }}</td>\n' +
    '						<td class="hidden-sm hidden-xs">{{ player.buyout }}</td>\n' +
    '						<td>\n' +
    '							<span class="label" ng-class="{\'label-success\': (player.buyout - player.buyin > 0), \'label-danger\': (player.buyout - player.buyin < 0), \'label-default\': (player.buyout - player.buyin == 0)}" bs-popover data-content="{{\'buyin: \' + player.buyin + \', buyout: \' + player.buyout}}" data-placement="bottom" data-trigger="hover touchup">{{ (player.buyout - player.buyin) }}</span>\n' +
    '						</td>\n' +
    '						<td>{{ (player.gamesCount != 0) && ((player.buyout - player.buyin) / player.gamesCount) | number:2 }}</td>\n' +
    '						<td>{{ (player.gamesCount != 0) && (player.buyin / player.gamesCount) | number:2 }}</td>\n' +
    '					</tr>\n' +
    '				</tbody>\n' +
    '				<tfoot>\n' +
    '					<tr>\n' +
    '						<th>\n' +
    '							{{ vm.displayGames.players.length }} Players <span class="badge" title="Average games count">{{ vm.statsAvgGameCount() | number:0 }}</span>\n' +
    '						</th>\n' +
    '						<td class="hidden-sm hidden-xs">{{ vm.statsAvgBuyin() | number:2 }}</td>\n' +
    '						<td class="hidden-sm hidden-xs">{{ vm.statsAvgBuyout() | number:2 }}</td>\n' +
    '						<td>{{ vm.statsAvgWinnings() | number:2 }}</td>\n' +
    '						<td>{{ vm.statsAvgWinningsPerGame() | number:2 }}</td>\n' +
    '						<td>-</td>\n' +
    '					</tr>\n' +
    '				</tfoot>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
