angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/popovers/buy-popover-tpl.html',
    '<div class="popover">\n' +
    '	<div class="arrow"></div>\n' +
    '	<h3 class="popover-title" ng-bind="title" ng-show="title"></h3>\n' +
    '	<div class="popover-content">\n' +
    '		<div class="btn-group btn-group-sm buy-amount">\n' +
    '			<button class="btn btn-warning" ng-click="gameCtrl.cancelBuyin(player, 1)" ng-disabled="!player.isPlaying || player.buyin <= 0" title="Cancel Buy-in">\n' +
    '        <i class="icon icon-minus"></i>\n' +
    '        <span class="hidden-xs"> 1</span>\n' +
    '      </button>\n' +
    '			<button class="btn btn-warning buy-amount__small" ng-click="gameCtrl.cancelBuyin(player, 0.1)" ng-disabled="!player.isPlaying || player.buyin <= 0" title="Cancel Buy-in">\n' +
    '        <i class="icon icon-minus"></i>\n' +
    '        <span class="hidden-xs"> 0.1</span>\n' +
    '      </button>\n' +
    '			<button class="btn btn-default">{{player.buyin}}</button>\n' +
    '			<button class="btn btn-success buy-amount__small" ng-click="gameCtrl.buyin(player, 0.1)" ng-disabled="!player.isPlaying" title="Buy-in">\n' +
    '        <i class="icon icon-plus"></i>\n' +
    '        <span class="hidden-xs"> 0.1</span>\n' +
    '      </button>\n' +
    '			<button class="btn btn-success" ng-click="gameCtrl.buyin(player, 1)" ng-disabled="!player.isPlaying" title="Buy-in">\n' +
    '        <i class="icon icon-plus"></i>\n' +
    '        <span class="hidden-xs"> 1</span>\n' +
    '      </button>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
