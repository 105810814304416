angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/user-profile/user-profile.view.html',
    '<paper-card class="container-fluid" image="{{ $ctrl.currentUser.provider === \'google\' ? $ctrl.currentUser.photoURL + \'?sz=150\' : $ctrl.currentUser.photoURL }}" alt="User\'s profile image">\n' +
    '  <div class="card-content">\n' +
    '    <div ng-if="$ctrl.currentUser">\n' +
    '      <upload-button on-change="$ctrl.saveProfileImage($event)"></upload-button>\n' +
    '      <p class="text-info" ng-if="$ctrl.uploadMsg">{{ $ctrl.uploadMsg }}</p>\n' +
    '      <community-picker communities="$ctrl.communities" selected="$ctrl.selectedCommunity" on-select="$ctrl.communitySelectionChanged($event)"></community-picker>\n' +
    '    </div>\n' +
    '    <player-details ng-show="!!$ctrl.currentUser.playerId" player-id="$ctrl.currentUser.playerId" community-filter="$ctrl.selectedCommunity"></player-details>\n' +
    '    <signup-login-form on-login="$ctrl.loggedIn($event)" on-logout="$ctrl.loggedOut($event)"></signup-login-form>\n' +
    '  </div>\n' +
    '  <div class="card-actions">\n' +
    '    <paper-button ng-if="$ctrl.currentUser" ng-tap="$ctrl.logout()">Logout</paper-button>\n' +
    '  </div>\n' +
    '</paper-card>\n' +
    '');
}]);
