angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/join-community/join-community.view.html',
    '<div class="container-fluid">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <div class="loader" ng-show="!$ctrl.community">Loading...</div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <p ng-show="!$ctrl.joined && $ctrl.currentUser">\n' +
    '        To join the Pokermunity &quot;{{$ctrl.community.name}}&quot;, enter the password you received from the admin and click the &quot;join&quot; button.\n' +
    '      </p>\n' +
    '      <p ng-show="$ctrl.joined && !$ctrl.approved">\n' +
    '        You have already asked to join the Pokermunity &quot;{{$ctrl.community.name}}&quot;.\n' +
    '        <em>(A request was sent to the group\'s admin)</em>\n' +
    '      </p>\n' +
    '      <p ng-show="$ctrl.approved">\n' +
    '        You are already a member of this community.\n' +
    '        <a ui-sref="community({communityId: $ctrl.community.$id})"> Go to <strong>{{$ctrl.community.name}}</strong></a>\n' +
    '      </p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row" ng-show="!$ctrl.currentUser">\n' +
    '    <div class="col-xs-12">\n' +
    '      <signup-login-form on-login="$ctrl.postLogin($event)"></signup-login-form>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row" ng-show="!$ctrl.joined && !$ctrl.approved && $ctrl.currentUser">\n' +
    '    <div class="col-xs-8">\n' +
    '      <paper-input-container>\n' +
    '        <label>Community join password</label>\n' +
    '        <input is="iron-input" name="joinPass" ng-model="$ctrl.joinCode">\n' +
    '      </paper-input-container>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '      <paper-button raised ng-tap="$ctrl.joinCommunity()">join &quot;{{$ctrl.community.name}}&quot;</paper-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
