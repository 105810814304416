angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/games/games.view.html',
    '<button class="btn btn-default players-drawer-btn visible-xs" ng-class="{ \'players-drawer-open\': vm.prefs.playersOpen }" ng-click="vm.openPlayersControl()"><span class="icon icon-user-plus"></span></button>\n' +
    '\n' +
    '<div class="row">\n' +
    '  <div class="col-md-9">\n' +
    '    <attendance-panel players="vm.playersAndGuests" game-id="vm.game.$id" community-id="vm.game.communityId"\n' +
    '                      on-update="vm.updateAttendance($event)"></attendance-panel>\n' +
    '  </div>\n' +
    '  <div class="col-md-3">\n' +
    '    <paper-button raised ng-tap="vm.addPlayersToGame(vm.attendingPlayersIds)">Add all approved players</paper-button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '  <div id="players-control" class="col-sm-4" ng-class="{\'nav-visible\': vm.prefs.playersOpen}">\n' +
    '    <h3 class="hidden-xs"><i class="icon icon-user"></i> Players</h3>\n' +
    '    <div class="row">\n' +
    '      <div class="col-xs-8">\n' +
    '        <div class="btn-group">\n' +
    '          <button title="Close" class="btn btn-danger visible-xs" id="close-players-panel"\n' +
    '                  ng-click="vm.closePlayersControl()"><i class="icon icon-remove"></i></button>\n' +
    '          <button class="btn btn-success" ng-click="vm.openPlayerDetailsDialog()"><i class="icon icon-plus"></i> New\n' +
    '            Player\n' +
    '          </button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '      <div class="col-md-12">\n' +
    '        <input class="form-control" ng-model="vm.playersFilter" placeholder="Player\'s name or other property"/>\n' +
    '        <table class="table table-striped table-condensed">\n' +
    '          <tbody>\n' +
    '          <tr ng-repeat="player in vm.playersAndGuests | filter:vm.playersFilter | orderBy:\'-gamesCount\' track by player.$id">\n' +
    '            <td>\n' +
    '              <!--<button class="btn btn-sm btn-default" ng-click="vm.openPlayerDetailsDialog(player)" ng-disabled="player.isPlaying"><i class="icon icon-pencil"></i></button>-->\n' +
    '              <a class="btn btn-sm btn-default" ui-sref="player({playerId: player.$id})"><i\n' +
    '                class="icon icon-pencil"></i></a>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <a ng-href="mailto:{{ player.email }}" title="{{ player.email }}" target="_blank">{{ player.displayName }}</a>\n' +
    '              <br/>\n' +
    '              <a ng-href="tel:{{ player.phone }}">{{ player.phone }}</a>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <span class="label"\n' +
    '                    ng-class="{\'label-success\': (player.balance > 0), \'label-danger\': (player.balance < 0), \'label-default\': (player.balance == 0)}">{{ player.balance }}</span>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '              <button class="btn btn-sm btn-default" ng-click="vm.addPlayerToGame(player)" title="Join game"><i\n' +
    '                class="icon icon-chevron-right"></i>\n' +
    '              </button>\n' +
    '            </td>\n' +
    '          </tr>\n' +
    '          </tbody>\n' +
    '        </table>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="col-sm-8">\n' +
    '    <on-going-game game-id="vm.game.$id" on-game-start="vm.addPlayersToGame($event)"></on-going-game>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
