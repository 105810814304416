angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/manager/attendance-panel-tmpl.html',
    '<div class="attendance-panel-header" ng-class="{\'attendance-panel__open\': $ctrl.open}">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-3" uib-tooltip="In">\n' +
    '      <span class="icon icon-check text-success"></span>\n' +
    '      <strong class="text-success attending-count-yes" ng-bind="$ctrl.attendanceCount.yes.length || 0"></strong>\n' +
    '    </div>\n' +
    '    <div class="col-xs-3" uib-tooltip="Maybe">\n' +
    '      <span class="icon icon-question text-warning"></span>\n' +
    '      <strong class="text-warning attending-count-maybe" ng-bind="$ctrl.attendanceCount.maybe.length || 0"></strong>\n' +
    '    </div>\n' +
    '    <div class="col-xs-3" uib-tooltip="Out">\n' +
    '      <span class="icon icon-remove text-danger"></span>\n' +
    '      <strong class="text-danger attending-count-no" ng-bind="$ctrl.attendanceCount.no.length || 0"></strong>\n' +
    '    </div>\n' +
    '    <div class="col-xs-3">\n' +
    '      <paper-button class="pull-right" ng-tap="$ctrl.toggleBody()"><span class="icon" ng-class="{\'icon-arrow_drop_down\': !this.open, \'icon-arrow_drop_up\': this.open}"></span></paper-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="attendance-panel-body">\n' +
    '  <div class="row" ng-show="$ctrl.isAdmin">\n' +
    '    <div class="col-xs-12">\n' +
    '      <players-picker players="$ctrl.players" selected="$ctrl.currentUser.playerId"\n' +
    '                      on-select="$ctrl.playerSelected($event)"></players-picker>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12 game-approve-panel">\n' +
    '      <label>Can {{ $ctrl.currentPlayer.displayName }} attend?</label>\n' +
    '      <paper-button class="game-approve-panel__yes" data-answer="yes" raised>\n' +
    '        <span class="icon icon-check"></span>\n' +
    '        <span>&nbsp;I\'m in</span>\n' +
    '      </paper-button>\n' +
    '      <paper-button class="game-approve-panel__maybe" data-answer="maybe" raised>\n' +
    '        <span class="icon icon-question"></span>\n' +
    '        <span>&nbsp;Not sure yet</span>\n' +
    '      </paper-button>\n' +
    '      <paper-button class="game-approve-panel__no" data-answer="no" raised>\n' +
    '        <span class="icon icon-remove"></span>\n' +
    '        <span>&nbsp;Can\'t make it</span>\n' +
    '      </paper-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-4">\n' +
    '      <span class="icon icon-check text-success"></span>\n' +
    '      <span> In </span>\n' +
    '      <strong class="text-success attending-count-yes" ng-bind="$ctrl.attendanceCount.yes.length || 0"></strong>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '      <span class="icon icon-question text-warning"></span>\n' +
    '      <span> Maybe </span>\n' +
    '      <strong class="text-warning attending-count-maybe" ng-bind="$ctrl.attendanceCount.maybe.length || 0"></strong>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '      <span class="icon icon-remove text-danger"></span>\n' +
    '      <span> Out </span>\n' +
    '      <strong class="text-danger attending-count-no" ng-bind="$ctrl.attendanceCount.no.length || 0"></strong>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-4">\n' +
    '      <ul class="unpadded-list">\n' +
    '        <li ng-repeat="attending in $ctrl.attendanceCount.yes">\n' +
    '          <div class="thumbnail">\n' +
    '            <img ng-src="{{ attending.photoURL }}" uib-tooltip="{{ attending.displayName }}">\n' +
    '            <strong>\n' +
    '              {{ attending.displayName }}\n' +
    '            </strong>\n' +
    '          </div>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '      <ul class="unpadded-list">\n' +
    '        <li ng-repeat="attending in $ctrl.attendanceCount.maybe">\n' +
    '          <div class="thumbnail">\n' +
    '            <img ng-src="{{ attending.photoURL }}">\n' +
    '            <strong>\n' +
    '              {{ attending.displayName }}\n' +
    '            </strong>\n' +
    '          </div>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '      <ul class="unpadded-list">\n' +
    '        <li ng-repeat="attending in $ctrl.attendanceCount.no">\n' +
    '          <div class="thumbnail">\n' +
    '            <img ng-src="{{ attending.photoURL }}">\n' +
    '            <strong>\n' +
    '              {{ attending.displayName }}\n' +
    '            </strong>\n' +
    '          </div>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
