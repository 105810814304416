angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/home/home.view.html',
    '<div class="container">\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-6 col-md-offset-3">\n' +
    '          <div class="welcome-message" ng-class="{\'welcome-message--visible\': vm.inHomeRoute()}">\n' +
    '            <h2>Welcome to Pokermunity</h2>\n' +
    '            <p>\n' +
    '              Here you can track your home poker game results in relation to the people you play with.\n' +
    '            </p>\n' +
    '            <p></p>\n' +
    '            <p>\n' +
    '              You can <paper-button raised ng-click="vm.startAddingCommunity()">create</paper-button> or\n' +
    '              <em id="join-community-help" uib-tooltip="To join a community, ask the admin to send you a joining link">join <span class="icon icon-question-circle"></span></em>\n' +
    '              a Pokermmunity (=the group of players you usually play with)<span ng-if="!vm.currentUser">, but first you\'ll need to <a ui-sref="userprofile({uid: \'\'})">login</a></span>\n' +
    '            </p>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <community-picker communities="vm.communities" on-select="vm.gotoCommunity($event)"></community-picker>\n' +
    '    <div class="row new-community" ng-class="{\'new-community__active\': vm.editingCommunity}">\n' +
    '      <div class="col-xs-12">\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <div class="input-group">\n' +
    '              <label class="sr-only" ng-bind="vm.addingCommunity ? \'Community name\' : \'Joining code (ask admin)\'"></label>\n' +
    '              <input class="form-control" type="text" ng-model="vm.newCommunity" ng-disabled="vm.communityInputDisabled"\n' +
    '                     placeholder="{{vm.addingCommunity ? \'Community name\' : \'Joining code (ask admin)\'}}">\n' +
    '              <div class="input-group-btn">\n' +
    '                <button class="btn btn-default new-community-btn" ng-click="vm.saveCommunity()">\n' +
    '                  <span class="icon icon-cloud-done"></span>\n' +
    '                  <span class="new-community-btn-text"> Save</span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-default" ng-click="vm.closeCommunityEdit()">\n' +
    '                  <span class="icon icon-delete"></span>\n' +
    '                  <span class="new-community-btn-text"> Cancel</span>\n' +
    '                </button>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div class="row">\n' +
    '          <div class="col-xs-12">\n' +
    '            <game-settings game="vm.defaultSettings" on-update="vm.settingsUpdated(settings)"></game-settings>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-12">\n' +
    '            <div class="row">\n' +
    '                <div class="col-xs-12" ui-view></div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
