angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/tmpls/login-state-tmpl.html',
    '<span class="login-state-logged-in" ng-show="authCtrl.user">\n' +
    '    <a class="btn btn-link btn-sm navbar-btn" ui-sref="userprofile({uid: authCtrl.user.uid})">\n' +
    '        <span>\n' +
    '          Logged in as\n' +
    '            <img class="img-circle"\n' +
    '                 ng-src="{{ authCtrl.user.provider === \'google\' ? authCtrl.user.photoURL + \'?sz=25\' : authCtrl.user.photoURL }}"\n' +
    '                 alt="profile image"\n' +
    '            /> {{ authCtrl.user.displayName }}\n' +
    '        </span>\n' +
    '    </a>\n' +
    '    <button class="btn btn-sm btn-link navbar-btn" ng-click="authCtrl.signOut()">\n' +
    '      <span class="icon icon-google-plus"></span><span> logout</span>\n' +
    '    </button>\n' +
    '</span>\n' +
    '');
}]);
