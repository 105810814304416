angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/tmpls/player-details-tmpl.html',
    '<form role="form" class="form-horizontal">\n' +
    '  <div class="form-group">\n' +
    '    <div class="col-xs-5 col-xs-offset-7">\n' +
    '      <paper-button class="pull-right" raised\n' +
    '        ng-tap="pCtrl.giveUserAdminPrivileges()"\n' +
    '      >\n' +
    '        <span class="icon icon-user-plus"></span>\n' +
    '        <span> Set admin</span>\n' +
    '      </paper-button>\n' +
    '      <paper-button class="pull-right" raised\n' +
    '        ng-tap="pCtrl.promoteGuestToMember()"\n' +
    '      >\n' +
    '        <span class="icon icon-group_add"></span>\n' +
    '        <span> Set member</span>\n' +
    '      </paper-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label class="col-sm-4 control-label">Added on</label>\n' +
    '    <div class="col-sm-8">\n' +
    '      <p class="form-control-static">\n' +
    '        {{pCtrl.player.createDate | date:\'y-MM-dd\'}}\n' +
    '      </p>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <div class="col-xs-12">\n' +
    '      <paper-input class="player-displayName" label="Name"></paper-input>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <div class="col-xs-12">\n' +
    '      <paper-input class="player-email" label="Email"></paper-input>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <div class="col-xs-12">\n' +
    '      <paper-input class="player-phone" label="Phone"></paper-input>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label class="col-sm-4 control-label" for="playerBalance">Balance</label>\n' +
    '    <div class="col-sm-8">\n' +
    '      <input class="form-control" type="number" step="10" id="playerBalance"\n' +
    '        name="playerBalance" ng-model="pCtrl.player.balance" disabled="disabled"\n' +
    '      />\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</form>\n' +
    '\n' +
    '<player-performance player="pCtrl.player" games="pCtrl.filteredGames">\n' +
    '</player-performance>\n' +
    '');
}]);
