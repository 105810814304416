angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/modals/addNewPlayer.html',
    '<div class="modal-header">\n' +
    '	<h3>Player Details <!-- <button ng-model="isAdmin" class="btn btn-sm btn-default" uib-btn-checkbox btn-checkbox-true="true" btn-checkbox-false="false"><span class="icon icon-edit"></span></button> --></h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<player-details player-id="modalCtrl.player.$id" on-changes="modalCtrl.playerChanged(player)"></player-details>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn btn-primary" ng-click="modalCtrl.ok()"><i class="icon icon-check"></i> OK</button>\n' +
    '	<button class="btn btn-warning" ng-click="modalCtrl.cancel()"><i class="icon icon-remove"></i> Cancel</button>\n' +
    '</div>\n' +
    '');
}]);
