angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/players/players.view.html',
    '<h3 class="hidden-xs"><i class="icon icon-user"></i> Players</h3>\n' +
    '<div class="row">\n' +
    '    <div class="col-xs-8">\n' +
    '        <div class="btn-group">\n' +
    '            <button title="Close" class="btn btn-danger visible-xs" id="close-players-panel" ng-click="vm.closePlayersControl()"><i class="icon icon-remove"></i></button>\n' +
    '            <button class="btn btn-success" ng-click="vm.openPlayerDetailsDialog()"><i class="icon icon-plus"></i> New Player</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '        <button class="btn btn-primary pull-right" ng-click="vm.refreshPlayersList()"><i class="icon icon-refresh"></i> <span class="hidden-xs">Refresh</span></button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '    <div class="col-md-12">\n' +
    '        <input class="form-control" ng-model="vm.playersFilter" placeholder="Player\'s name or other property"/>\n' +
    '        <table class="table table-striped table-condensed">\n' +
    '            <tbody>\n' +
    '            <tr ng-repeat="player in vm.players | filter:playersFilter | orderBy:\'name\'">\n' +
    '                <td>\n' +
    '                    <button class="btn btn-sm btn-default" ng-click="vm.openPlayerDetailsDialog(player)" ng-disabled="player.isPlaying"><i class="icon icon-pencil"></i></button>\n' +
    '                </td>\n' +
    '                <td>\n' +
    '                    <a href="mailto:{{ player.email }}" title="{{ player.email }}" target="_blank">{{ player.displayName }}</a>\n' +
    '                    <br/>\n' +
    '                    <a href="tel:{{ player.phone }}">{{ player.phone }}</a>\n' +
    '                </td>\n' +
    '                <td>\n' +
    '                    <span class="label" ng-class="{\'label-success\': (player.balance > 0), \'label-danger\': (player.balance < 0), \'label-default\': (player.balance == 0)}">{{ player.balance }}</span>\n' +
    '                </td>\n' +
    '                <td>\n' +
    '                    <button class="btn btn-sm btn-default" ng-click="vm.addPlayerToGame(player)" ng-disabled="player.isPlaying" title="Join game"><i class="icon icon-chevron-right"></i>\n' +
    '                    </button>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
