angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/tmpls/on-going-game-tmpl.html',
    '<div class="current-game">\n' +
    '  <div class="game-editing-panel" ng-if="vm.isAdmin">\n' +
    '    <game-settings game="vm.game" on-update="vm.gameSettingsChanged(settings)"></game-settings>\n' +
    '    <game-details game="vm.game" on-update="vm.gameDetailsChanged(details)"></game-details>\n' +
    '  </div>\n' +
    '\n' +
    '	<!-- <button class="btn btn-default players-drawer-btn visible-xs" ng-click="vm.openPlayersControl()"><span class="icon icon-arrow-right"></span></button> -->\n' +
    '  <h4>{{ vm.game.date | date:\'fullDate\' }},  # of players: {{ vm.playersInGame.length | number }}</h4>\n' +
    '\n' +
    '	<button class="btn btn-default" ng-click="vm.startGame()" ng-disabled="!vm.playersInGame.length">Start game</button>\n' +
    '\n' +
    '	<div class="row game-container" ng-if="vm.isAdmin">\n' +
    '		<div class="col-sm-6 col-xs-12 fader" ng-repeat="player in vm.playersInGame | orderBy:\'displayName\'">\n' +
    '			<player-card player="player" on-update="vm.playerResultUpdated(updates)"></player-card>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<ul class="nav nav-pills" role="tablist">\n' +
    '		<li role="presentation"><a href="">Total Buyin <span class="badge">{{ vm.totalBuyin() }}</span></a></li>\n' +
    '		<li role="presentation"><a href="">Total Chips <span class="badge">{{ vm.totalChips() }}</span></a></li>\n' +
    '		<li role="presentation"><a href="">Total Buyout <span class="badge">{{ vm.totalChips() / vm.game.chipValue }}</span></a></li>\n' +
    '		<li role="presentation"><a href="">Total Hosting <span class="badge">{{ vm.totalHosting() }}</span></a></li>\n' +
    '	</ul>\n' +
    '	<!--<div class="row bg-info end-game-wrap">-->\n' +
    '		<!--<div class="col-md-12">-->\n' +
    '			<!--<button ng-click="vm.saveGame()" class="btn btn-block btn-primary" ng-disabled="(vm.playersInGame.length <= 0) || vm.isGameInProgress()" analytics-on analytics-event="Save Game" analytics-category="Actions" analytics-label="{{ vm.game.location }}"><i class="icon icon-download"></i>&nbsp;End Game</button>-->\n' +
    '		<!--</div>-->\n' +
    '	<!--</div>-->\n' +
    '\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <paper-confirm-button on-action-confirmed="vm.deleteGame()">\n' +
    '        <i class="icon icon-trash"></i>\n' +
    '        <span> Delete game</span>\n' +
    '      </paper-confirm-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
