angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/communities/communities.view.html',
    '<paper-card class="container-fluid community-wrap" heading="{{ vm.community.name }}" alt="{{ vm.community.name }}">\n' +
    '  <div class="card-content">\n' +
    '    <div class="row" ng-if="!vm.currentUser || !vm.isMember" ng-show="vm.inCommunityRoute()">\n' +
    '      <div class="col-xs-12">\n' +
    '        <h3>Not a member</h3>\n' +
    '        <a ui-sref="joinCommunity({communityId: vm.community.$id})">\n' +
    '          <paper-button raised>Join</paper-button>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div ng-if="!!vm.currentUser">\n' +
    '      <div class="row" ng-show="!vm.inCommunityRoute()">\n' +
    '        <div class="col-xs-5">\n' +
    '          <a ui-sref="community({communityId: vm.community.$id})">\n' +
    '            <paper-button raised>\n' +
    '              <span class="icon icon-arrow_back"></span>\n' +
    '              <span>{{ vm.community.name }}</span>\n' +
    '            </paper-button>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row">\n' +
    '        <nav class="col-xs-12">\n' +
    '          <ul class="nav nav-tabs">\n' +
    '            <li role="presentation" ui-sref-active="active">\n' +
    '              <a ui-sref="rsvp({communityId: vm.community.$id})">\n' +
    '                <span class="icon icon-schedule"></span>\n' +
    '                <span> RSVP</span>\n' +
    '              </a>\n' +
    '            </li>\n' +
    '            <li role="presentation" ui-sref-active="active">\n' +
    '              <a ui-sref="stats({ fromDate: vm.fromDate, toDate: vm.toDate })">\n' +
    '                <span class="icon icon-bar-chart"></span>\n' +
    '                <span> Stats</span>\n' +
    '              </a>\n' +
    '            </li>\n' +
    '            <li role="presentation" ui-sref-active="active" ng-if="vm.isAdmin">\n' +
    '              <a ui-sref="communityPlayers({communityId: vm.community.$id})">\n' +
    '                <span class="icon icon-people"></span>\n' +
    '                <paper-badge ng-if="vm.joiners.length" label="{{vm.joiners.length || 0}}"></paper-badge>\n' +
    '                <span> Players</span>\n' +
    '              </a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </nav>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="vm.isAdmin" ng-show="vm.inCommunityRoute()">\n' +
    '        <div class="col-xs-4">\n' +
    '          <label class="sr-only">Join code</label>\n' +
    '          <input class="form-control" placeholder="Join code" type="text" ng-model="vm.community.joinCode">\n' +
    '        </div>\n' +
    '        <div class="col-xs-4 col-sm-2">\n' +
    '          <paper-button raised ng-click="vm.generateJoinLink()" uib-tooltip="Save">\n' +
    '            <span class="icon icon-floppy-o"></span>\n' +
    '          </paper-button>\n' +
    '        </div>\n' +
    '        <div class="col-xs-4 col-sm-6">\n' +
    '          <!--<span>https://ourhomegame.com/join-community/{{vm.community.$id}}?joinCode={{vm.community.joinCode}}</span>-->\n' +
    '          <paper-button raised ngclipboard data-clipboard-text="https://ourhomegame.com/join-community/{{vm.community.$id}}" uib-tooltip="Copy to clipboard">\n' +
    '            <span class="icon icon-clipboard"></span>\n' +
    '          </paper-button>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ng-if="!!vm.games && vm.isAdmin">\n' +
    '        <div class="col-xs-12">\n' +
    '          <ul class="pagination">\n' +
    '            <li ng-click="vm.prevPage()">\n' +
    '              <a aria-label="Previous">\n' +
    '                <span class="icon icon-arrow-left" aria-hidden="true"></span>\n' +
    '              </a>\n' +
    '            </li>\n' +
    '            <li ng-repeat="game in vm.games | orderBy:\'date\' | limitTo:vm.pageSize:vm.currentPage" ui-sref-active="active">\n' +
    '              <a ui-sref="game({ gameId: game.$id })" title="{{ game.$id }}" role="tab">{{ game.date | date:\'EEE, dd/MM\' }} {{ game.title || \'@ \' + game.location }}</a>\n' +
    '            </li>\n' +
    '            <li ng-click="vm.nextPage()">\n' +
    '              <a aria-label="Next">\n' +
    '                <span class="icon icon-arrow-right" aria-hidden="true"></span>\n' +
    '              </a>\n' +
    '            </li>\n' +
    '          </ul>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="row" ui-view></div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <div class="card-actions">\n' +
    '    <paper-button ng-click="vm.createGame( vm.community )">New Game</paper-button>\n' +
    '  </div>\n' +
    '</paper-card>\n' +
    '');
}]);
