angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/user-profile/signup-form.view.html',
    '<form ng-show="!$ctrl.currentUser" name="$ctrl.userProfileForm">\n' +
    '  <fieldset>\n' +
    '    <!--<legend>Social login</legend>-->\n' +
    '    <paper-button class="google-login-btn" raised>\n' +
    '      <!--<span class="icon icon-google-plus"></span>-->\n' +
    '      <img src="//www.gstatic.com/mobilesdk/160512_mobilesdk/auth_service_google.svg" alt="Google logo">\n' +
    '      <span>&nbsp;Login</span>\n' +
    '    </paper-button>\n' +
    '  </fieldset>\n' +
    '  <hr>\n' +
    '  <fieldset>\n' +
    '    <!--<legend>Email login</legend>-->\n' +
    '    <label>\n' +
    '      <input type="checkbox" ng-model="$ctrl.signingUp">\n' +
    '      <span>Sign up</span>\n' +
    '    </label>\n' +
    '    <paper-input-container auto-validate ng-show="$ctrl.signingUp">\n' +
    '      <label>Name</label>\n' +
    '      <input is="iron-input" name="name" ng-model="$ctrl.userInputs.name" ng-required="$ctrl.signingUp">\n' +
    '      <paper-input-error>Name is mandatory</paper-input-error>\n' +
    '    </paper-input-container>\n' +
    '    <paper-input-container auto-validate>\n' +
    '      <label>Email</label>\n' +
    '      <input is="iron-input" name="email" ng-model="$ctrl.userInputs.email" ng-required="$ctrl.userProfileForm.email.$touched">\n' +
    '    </paper-input-container>\n' +
    '    <paper-input-container auto-validate>\n' +
    '      <label>Password</label>\n' +
    '      <input is="iron-input" name="password" type="password" ng-model="$ctrl.userInputs.pass" pattern="[\\w]{6,}" ng-required="$ctrl.userProfileForm.password.$touched">\n' +
    '      <paper-input-error>Password should be at least 6 characters long</paper-input-error>\n' +
    '    </paper-input-container>\n' +
    '    <paper-button class="signup-button" raised ng-tap="$ctrl.signup()" ng-show="$ctrl.signingUp">\n' +
    '      <span class="icon icon-user-circle-o"></span>\n' +
    '      <span> Signup</span>\n' +
    '    </paper-button>\n' +
    '    <paper-button class="login-button" raised ng-tap="$ctrl.login(\'email\')" ng-hide="$ctrl.signingUp">\n' +
    '      <span class="icon icon-user-circle-o"></span>\n' +
    '      <span> Login</span>\n' +
    '    </paper-button>\n' +
    '  </fieldset>\n' +
    '  <fieldset class="login-errors">\n' +
    '    <p class="text-danger" ng-if="$ctrl.loginErrorMessage">{{ $ctrl.loginErrorMessage }}</p>\n' +
    '  </fieldset>\n' +
    '</form>\n' +
    '');
}]);
