angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/players/players-merger.view.html',
    '<div class="container-fluid">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-4">\n' +
    '      <vaadin-combo-box class="target-player" label="Target Player"></vaadin-combo-box>\n' +
    '    </div>\n' +
    '    <div class="col-xs-4">\n' +
    '      <vaadin-combo-box class="source-player" label="Source Player"></vaadin-combo-box>\n' +
    '    </div>\n' +
    '    <div class="col-xs-3">\n' +
    '      <paper-button raised ng-click="$ctrl.mergePlayers()">merge</paper-button>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
