angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/tmpls/player-card-tmpl.html',
    '<div class="player-card" ng-class="{\'bg-success\': (player.buyout - player.buyin > 0), \'bg-danger\': (player.buyout - player.buyin < 0)}">\n' +
    '	<button class="btn btn-xs btn-danger cancel-btn" title="Cancel" ng-disabled="!player.isPlaying" ng-click="gameCtrl.cancelAddPlayer(player)"><span class="icon icon-trash"></span></button>\n' +
    '	<header class="well well-sm" title="{{player.displayName}}">\n' +
    '		<strong ng-class="{\'text-success\': (player.buyout - player.buyin > 0), \'text-danger\': (player.buyout - player.buyin < 0)}">\n' +
    '			<span class="icon icon-user"></span>&nbsp;{{player.displayName}}\n' +
    '		</strong>\n' +
    '	</header>\n' +
    '	<div class="player-card-content">\n' +
    '    <form>\n' +
    '      <fieldset class="form-group">\n' +
    '        <label>Buyin</label>\n' +
    '        <div class="input-group input-group-sm buy-amount">\n' +
    '          <div class="input-group-btn">\n' +
    '            <button class="btn btn-warning" ng-click="gameCtrl.cancelBuyin(player, 1)" ng-disabled="!player.isPlaying || player.buyin <= 0" title="Cancel Buy-in">\n' +
    '              <i class="icon icon-minus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 1</span>\n' +
    '            </button>\n' +
    '            <button class="btn btn-warning buy-amount__small" ng-click="gameCtrl.cancelBuyin(player, 0.1)" ng-disabled="!player.isPlaying || player.buyin <= 0" title="Cancel Buy-in">\n' +
    '              <i class="icon icon-minus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 0.1</span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <button class="btn btn-default form-control">{{player.buyin}}</button>\n' +
    '          <div class="input-group-btn">\n' +
    '            <button class="btn btn-success buy-amount__small" ng-click="gameCtrl.buyin(player, 0.1)" ng-disabled="!player.isPlaying" title="Buy-in">\n' +
    '              <i class="icon icon-plus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 0.1</span>\n' +
    '            </button>\n' +
    '            <button class="btn btn-success" ng-click="gameCtrl.buyin(player, 1)" ng-disabled="!player.isPlaying" title="Buy-in">\n' +
    '              <i class="icon icon-plus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 1</span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </fieldset>\n' +
    '      <fieldset class="form-group">\n' +
    '        <label>Chips</label>\n' +
    '        <div class="input-group input-group-sm buy-amount">\n' +
    '          <div class="input-group-btn">\n' +
    '            <button class="btn btn-danger" ng-click="gameCtrl.addOrSubtractChips(player, 1)" ng-disabled="!player.isPlaying">\n' +
    '              <i class="icon icon-minus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 1</span>\n' +
    '            </button>\n' +
    '            <button class="btn btn-danger buy-amount__small" ng-click="gameCtrl.addOrSubtractChips(player, 0.10)" ng-disabled="!player.isPlaying">\n' +
    '              <i class="icon icon-minus"></i>\n' +
    '              <span class="hidden-xs hidden-sm">0.1</span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '          <input class="form-control" ng-model="player.currentChipCount" ng-change="gameCtrl.chipCountUpdate(player)" type="number" min="0" step="{{ settings.chipValue }}" ng-disabled="!player.isPlaying"/>\n' +
    '          <div class="input-group-btn">\n' +
    '            <button class="btn btn-success buy-amount__small" ng-click="gameCtrl.addOrSubtractChips(player, 0.10, true)" ng-disabled="!player.isPlaying">\n' +
    '              <i class="icon icon-plus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 0.1</span>\n' +
    '            </button>\n' +
    '            <button class="btn btn-success" ng-click="gameCtrl.addOrSubtractChips(player, 1, true)" ng-disabled="!player.isPlaying">\n' +
    '              <i class="icon icon-plus"></i>\n' +
    '              <span class="hidden-xs hidden-sm"> 1</span>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </fieldset>\n' +
    '      <fieldset class="form-group">\n' +
    '        <label>Buyout</label>\n' +
    '        <p class="form-control-static">\n' +
    '          <strong ng-class="{\'text-success\': (player.buyout - player.buyin > 0), \'text-danger\': (player.buyout - player.buyin < 0)}">{{player.buyout}}</strong>\n' +
    '        </p>\n' +
    '      </fieldset>\n' +
    '      <fieldset class="form-group">\n' +
    '        <label>Actions</label>\n' +
    '        <div class="btn-group btn-group-sm">\n' +
    '          <button ng-click="gameCtrl.buyout(player)" class="btn btn-primary" ng-disabled="!player.isPlaying" title="Buy out">\n' +
    '            <i class="icon icon-external-link"></i>\n' +
    '            <span class="hidden-xs"> Buy out</span>\n' +
    '          </button>\n' +
    '          <button ng-click="gameCtrl.bust(player)" class="btn btn-danger" ng-disabled="!player.isPlaying" title="Bust">\n' +
    '            <i class="icon icon-trash"></i>\n' +
    '            <span class="hidden-xs"> Bust</span>\n' +
    '          </button>\n' +
    '          <button ng-click="gameCtrl.comeBack(player)" class="btn btn-primary" ng-disabled="player.isPlaying" title="Come Back">Come Back</button>\n' +
    '        </div>\n' +
    '      </fieldset>\n' +
    '      <fieldset class="form-group">\n' +
    '        <label>Hosting</label>\n' +
    '        <button ng-model="player.paidHosting" ng-click="onUpdate({updates: player})" class="btn btn-sm btn-default" uib-btn-checkbox btn-checkbox-true="true" btn-checkbox-false="false">\n' +
    '          <span class="icon" ng-class="player.paidHosting && \'icon-check-square-o\' || \'icon-exclamation-triangle\'"></span>&nbsp;<span>{{player.paidHosting && \'Paid\' || \'Owes\'}}</span>\n' +
    '        </button>\n' +
    '      </fieldset>\n' +
    '    </form>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
