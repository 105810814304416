angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/manager/rsvp-view-tmpl.html',
    '<div class="col-xs-12">\n' +
    '  <h3 ng-show="!$ctrl.gamesForPicker.length">\n' +
    '    No games in the foreseeable future <span class="icon icon-frown-o"></span>\n' +
    '  </h3>\n' +
    '  <form name="$ctrl.rsvpForm" class="rsvp-view" ng-show="$ctrl.gamesForPicker.length">\n' +
    '    <div class="form-group row">\n' +
    '      <label class="col-xs-12">Select game</label>\n' +
    '      <div class="col-xs-12">\n' +
    '        <ul class="list-group available-games">\n' +
    '          <li class="list-group-item available-games_game" ng-repeat="game in $ctrl.gamesForPicker" ng-click="$ctrl.gameSelectionChanged(game.$id)">\n' +
    '            <span class="icon" ng-class="{\'icon-radio_button_unchecked\': $ctrl.selectedGame.$id !== game.$id, \'icon-radio_button_checked\': $ctrl.selectedGame.$id === game.$id}"></span>\n' +
    '            <strong>{{ game.title }}</strong>\n' +
    '            <em>{{ game.date | date:$ctrl.dateFormat }}</em>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group row">\n' +
    '      <label class="col-xs-12">Current count</label>\n' +
    '      <div class="col-xs-12 col-sm-4">\n' +
    '        <span class="icon icon-check text-success"></span>\n' +
    '        <strong class="text-success attending-count-yes">{{ $ctrl.attendanceCount.yes.length || 0 }} In</strong>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-4">\n' +
    '        <span class="icon icon-question text-warning"></span>\n' +
    '        <strong class="text-warning attending-count-maybe">{{ $ctrl.attendanceCount.maybe.length || 0 }} Maybe</strong>\n' +
    '      </div>\n' +
    '      <div class="col-xs-12 col-sm-4">\n' +
    '        <span class="icon icon-remove text-danger"></span>\n' +
    '        <strong class="text-danger attending-count-no">{{ $ctrl.attendanceCount.no.length || 0 }} Out</strong>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group row">\n' +
    '      <strong class="col-xs-12">\n' +
    '        {{ $ctrl.selectedGame.title }}\n' +
    '      </strong>\n' +
    '      <div class="col-xs-12">\n' +
    '        {{ $ctrl.selectedGame.date | date:$ctrl.dateFormat }} @ {{ $ctrl.selectedGame.location }}\n' +
    '      </div>\n' +
    '      <div class="col-xs-12">\n' +
    '        <button class="btn btn-default" ng-click="$ctrl.showOnMap()">Show on map</button>\n' +
    '        <em>{{ $ctrl.selectedGame.address }}</em>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group row">\n' +
    '      <label class="col-xs-12">Your RSVP</label>\n' +
    '      <div class="col-xs-12">\n' +
    '        <ul class="list-group available-answers">\n' +
    '          <li class="list-group-item available-answers_answer" ng-class="{\'list-group-item-disabled\': $ctrl.gameFull && answer === \'yes\'}" ng-repeat="answer in $ctrl.availableAnswers" ng-click="$ctrl.changeAttendance(answer)">\n' +
    '            <span class="icon" ng-class="{\'icon-radio_button_unchecked\': $ctrl.playerAttendance.attendance !== answer, \'icon-radio_button_checked\': $ctrl.playerAttendance.attendance === answer}"></span>\n' +
    '            <span> {{ answer }}</span>\n' +
    '            <em class="text-danger" ng-show="$ctrl.gameFull && answer === \'yes\'"> {{ \'game is full :(\' }}</em>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group row">\n' +
    '      <label class="col-xs-12">Guests</label>\n' +
    '      <div class="col-xs-12">\n' +
    '        <input class="form-control" name="numOfGuests" type="number" ng-model="$ctrl.playerAttendance.guests" min="0" max="{{ $ctrl.selectedGame.allowedGuests }}" ng-disabled="!$ctrl.selectedGame.allowedGuests || !$ctrl.playerAttendance.attendance">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group row">\n' +
    '      <label class="col-xs-12">Comments</label>\n' +
    '      <div class="col-xs-12">\n' +
    '        <input class="form-control" name="attendanceComment" type="text" ng-model="$ctrl.playerAttendance.message" ng-disabled="!$ctrl.playerAttendance.attendance">\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="form-group row">\n' +
    '      <div class="col-xs-12">\n' +
    '        <label>Full list</label>\n' +
    '        <ul class="list-group rsvp-list" ng-repeat="answer in $ctrl.availableAnswers">\n' +
    '          <li class="list-group-item" ng-class="{\'list-group-item-success\': answer === \'yes\', \'list-group-item-warning\': answer === \'maybe\', \'list-group-item-danger\': answer === \'no\'}" ng-repeat="attendee in $ctrl.attendanceCount[answer]">\n' +
    '            <span class="icon" ng-class="{\'icon-check\': answer === \'yes\', \'icon-question\': answer === \'maybe\', \'icon-remove\': answer === \'no\'}"></span>\n' +
    '            <img class="img-circle" ng-src="{{ attendee.photoURL }}">\n' +
    '            <strong>{{ attendee.displayName }}</strong>\n' +
    '            <em>{{ attendee.message }}</em>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '        <label>Did not answer</label>\n' +
    '        <ul class="list-group">\n' +
    '          <li class="list-group-item list-group-item-info" ng-repeat="player in $ctrl.didNotAnswer">\n' +
    '            <span class="icon icon-question-circle"></span>\n' +
    '            <!--<img class="img-circle" ng-src="{{ attendee.photoURL }}">-->\n' +
    '            <strong>{{ player.name }}</strong>\n' +
    '          </li>\n' +
    '        </ul>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </form>\n' +
    '</div>\n' +
    '');
}]);
