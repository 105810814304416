angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/games/game-settings-tmpl.html',
    '<uib-accordion class="settings-menu">\n' +
    '  <uib-accordion-group>\n' +
    '    <uib-accordion-heading>\n' +
    '      <span class="icon icon-cogs"></span>\n' +
    '      <span> Game Preferences: Chip value {{ $ctrl.settings.chipValue }}, Default buyin {{ $ctrl.settings.defaultBuyin }}, Hosting {{ $ctrl.settings.hostingCosts }}, Guests {{ $ctrl.settings.allowedGuests }}</span>\n' +
    '    </uib-accordion-heading>\n' +
    '    <form class="form-inline row">\n' +
    '      <div class="form-group col-md-4">\n' +
    '        <label>Chip value:</label>\n' +
    '        <paper-slider id="chipValue" pin min="1" max="10" bind-from="$ctrl.settings.chipValue" on-update="$ctrl.chipsValueUpdated(value)"></paper-slider>\n' +
    '      </div>\n' +
    '      <div class="form-group col-md-4">\n' +
    '        <label for="defaultBuyin">Default buyin:</label>\n' +
    '        <input class="form-control default-buyin-control" type="number" min="0" step="10" id="defaultBuyin" ng-model="$ctrl.settings.defaultBuyin" />\n' +
    '      </div>\n' +
    '      <div class="form-group col-md-4">\n' +
    '        <label for="hostingCosts">Hosting costs:</label>\n' +
    '        <input class="form-control hosting-costs-control" type="number" min="10" step="10" id="hostingCosts" ng-model="$ctrl.settings.hostingCosts" />\n' +
    '      </div>\n' +
    '      <div class="form-group col-xs-12">\n' +
    '        <label>allow maximum of:</label>\n' +
    '        <pk-counter counter="$ctrl.settings.limitPlayers" on-update="$ctrl.limitPlayersUpdated(counter)"></pk-counter>\n' +
    '      </div>\n' +
    '      <div class="form-group col-xs-12">\n' +
    '        <label># allowed guests:</label>\n' +
    '        <pk-counter counter="$ctrl.settings.allowedGuests" on-update="$ctrl.allowedGuestsUpdated(counter)"></pk-counter>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '\n' +
    '    <paper-button class="save-community-default" raised>Save as community\'s default</paper-button>\n' +
    '  </uib-accordion-group>\n' +
    '</uib-accordion>\n' +
    '');
}]);
