angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/games/game-details-tmpl.html',
    '<form class="form-inline" role="form" name="$ctrl.gameDetails">\n' +
    '  <div class="form-group input-group-sm">\n' +
    '    <location-editor community-id="$ctrl.game.communityId" selected="$ctrl.details.location" on-select="$ctrl.locationSelected($event)"></location-editor>\n' +
    '    <paper-button class="show-on-map-btn" raised>show on map</paper-button>\n' +
    '    <em>{{ $ctrl.game.address }}</em>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <paper-input class="game-title-input" label="Game title"></paper-input>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label>date</label>\n' +
    '    <input class="form-control input-sm" type="text" ng-model="$ctrl.details.date" data-date-format="yyyy-MM-dd" data-date-type="number" data-autoclose="1" data-use-native="true" data-icon-left="icon icon-arrow-left" data-icon-right="icon icon-arrow-right" name="date" bs-datepicker>\n' +
    '  </div>\n' +
    '  <div class="form-group">\n' +
    '    <label># of hands:</label>\n' +
    '    <pk-counter counter="$ctrl.details.numberOfHands" on-update="$ctrl.numberOfHandsUpdated(counter)"></pk-counter>\n' +
    '  </div>\n' +
    '</form>\n' +
    '');
}]);
