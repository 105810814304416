angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/players/player-performance.view.html',
    '<div class="player-performance-container">\n' +
    '  <div class="loader" ng-show="$ctrl.loading">Loading...</div>\n' +
    '  <div ng-show="$ctrl.games.length" class="chart-holder"></div>\n' +
    '  <div ng-hide="loading">\n' +
    '    <button class="btn btn-info refresh-data-btn" analytics-on analytics-event="Player Performance" analytics-category="Actions" analytics-label="{{ $ctrl.player.displayName }}">Refresh <span class="icon icon-refresh"></span></button>\n' +
    '    <table class="table table-striped table-hover">\n' +
    '      <thead>\n' +
    '        <tr>\n' +
    '          <th>Date</th>\n' +
    '          <th>Profit/Loss</th>\n' +
    '          <th>Buys</th>\n' +
    '          <th>Balance</th>\n' +
    '        </tr>\n' +
    '      </thead>\n' +
    '      <tbody>\n' +
    '      <tr ng-repeat="game in $ctrl.games" ng-class="{\'success\': (game.balance > 0), \'danger\': (game.balance < 0)}">\n' +
    '        <td>\n' +
    '          <span>{{ game.date | date:\'y-MM-dd\' }} </span><a ui-sref="game({ communityId: game.communityId, gameId: (game.$id || game.id) })"><span class="icon icon-external-link"></span></a>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '          <span ng-class="{\'label label-success\': (game.buyout > game.buyin), \'label label-danger\': (game.buyout < game.buyin), \'label label-default\': (game.buyout == game.buyin)}">{{ game.buyout - game.buyin | number:0 }}</span>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '          <span ng-class="{\'label label-success\': (game.buyout > game.buyin), \'label label-danger\': (game.buyout < game.buyin), \'label label-default\': (game.buyout == game.buyin)}">{{ (game.buyout - game.buyin) / 50 | number:0 }}</span>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '          <span ng-class="{\'label label-success\': (game.balance > 0), \'label label-danger\': (game.balance < 0), \'label label-default\': (game.balance == 0)}">{{ game.balance | number:0 }}</span>\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '      </tbody>\n' +
    '      <tfoot>\n' +
    '      <tr>\n' +
    '        <th>Played {{ $ctrl.playerGamesCount }} games</th>\n' +
    '        <th>{{ $ctrl.playerWinningSessions }} winning sessions <span class="badge">{{ $ctrl.playerWinningSessions / ($ctrl.playerGamesCount || 1) | percentage:1 }}</span></th>\n' +
    '        <th>Average winnings (in Buys): <span class="badge">{{ $ctrl.playerAvgWinning | number:2 }}</span></th>\n' +
    '        <th>\n' +
    '          {{ $ctrl.games[$ctrl.games.length - 1].balance | number:0 }}\n' +
    '        </th>\n' +
    '      </tr>\n' +
    '      </tfoot>\n' +
    '    </table>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
