angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/login.html',
    '<link href="styles/signin.css" rel="stylesheet">\n' +
    '\n' +
    '<div class="container">\n' +
    '	<form class="form-signin" role="form" name="loginForm">\n' +
    '		<h2 class="form-signin-heading">Please sign in</h2>\n' +
    '		<google-plus-signin clientid="1053634869128-rj5rm5ilcdna5rhcp2n6ank7tj1j4rdq" language="en" ng-hide="authCtrl.user.authToken"></google-plus-signin>\n' +
    '		<div>\n' +
    '			Hi {{ authCtrl.user.name }}, <img class="img-circle" ng-src="{{ authCtrl.user.imgUrl }}" alt=""/>\n' +
    '			<button class="btn btn-danger" ng-click="authCtrl.signOut()"><span class="icon icon-google-plus"></span> logout</button>\n' +
    '		</div>\n' +
    '	</form>\n' +
    '</div>\n' +
    '');
}]);
