angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('scripts/players/community-players.view.html',
    '<div class="col-xs-12 community-players">\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <h3>Joining users</h3>\n' +
    '      <ul class="joining-users">\n' +
    '        <li ng-repeat="joiner in $ctrl.joiners">\n' +
    '          <!--<span>{{ joiner.email }}</span>-->\n' +
    '          <player-chip player="joiner"></player-chip>\n' +
    '          <paper-button raised ng-click="$ctrl.confirmPlayer(joiner)">\n' +
    '            <span class="icon icon-check"></span>\n' +
    '            <span> Confirm</span>\n' +
    '          </paper-button>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '      <!--<paper-card ng-repeat="joiner in vm.joiners">-->\n' +
    '        <!--<div class="card-content">-->\n' +
    '          <!--{{ joiner.email }}-->\n' +
    '        <!--</div>-->\n' +
    '        <!--<div class="card-actions">-->\n' +
    '          <!--<paper-button ng-click="vm.confirmPlayer(joiner)">Confirm</paper-button>-->\n' +
    '        <!--</div>-->\n' +
    '      <!--</paper-card>-->\n' +
    '    </div>\n' +
    '  </div>\n' +
    '  <h3>Merging players results</h3>\n' +
    '  <players-merger players="$ctrl.members" guests="$ctrl.guests"></players-merger>\n' +
    '  <div class="row">\n' +
    '    <div class="col-xs-12">\n' +
    '      <h3>Members</h3>\n' +
    '      <ul class="community-members">\n' +
    '        <li ng-repeat="member in $ctrl.members">\n' +
    '          <input type="checkbox" ng-model="$ctrl.selection[member.$id]">\n' +
    '          <player-chip player="member" ng-class="{\'selected\': $ctrl.selection[member.$id]}"></player-chip>\n' +
    '        </li>\n' +
    '      </ul>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);
