angular.module('pokerManager').run(['$templateCache', function($templateCache) {
  $templateCache.put('partials/popovers/count-popover-tpl.html',
    '<div class="popover">\n' +
    '	<div class="arrow"></div>\n' +
    '	<h3 class="popover-title" ng-bind="title" ng-show="title"></h3>\n' +
    '	<div class="popover-content">\n' +
    '		<div class="input-group input-group-sm buy-amount">\n' +
    '      <div class="input-group-btn">\n' +
    '        <button class="btn btn-danger" ng-click="gameCtrl.addOrSubtractChips(player, 1)" ng-disabled="!player.isPlaying">\n' +
    '          <i class="icon icon-minus"></i>\n' +
    '          <span class="hidden-xs"> 1</span>\n' +
    '        </button>\n' +
    '        <button class="btn btn-danger buy-amount__small" ng-click="gameCtrl.addOrSubtractChips(player, 0.10)" ng-disabled="!player.isPlaying">\n' +
    '          <i class="icon icon-minus"></i>\n' +
    '          <span class="hidden-xs">0.1</span>\n' +
    '        </button>\n' +
    '      </div>\n' +
    '			<input class="form-control" ng-model="player.currentChipCount" ng-change="gameCtrl.chipCountUpdate(player)" type="number" min="0" step="{{ settings.chipValue }}" ng-disabled="!player.isPlaying" autofocus/>\n' +
    '			<div class="input-group-btn">\n' +
    '        <button class="btn btn-success buy-amount__small" ng-click="gameCtrl.addOrSubtractChips(player, 0.10, true)" ng-disabled="!player.isPlaying">\n' +
    '          <i class="icon icon-plus"></i>\n' +
    '          <span class="hidden-xs"> 0.1</span>\n' +
    '        </button>\n' +
    '        <button class="btn btn-success" ng-click="gameCtrl.addOrSubtractChips(player, 1, true)" ng-disabled="!player.isPlaying">\n' +
    '          <i class="icon icon-plus"></i>\n' +
    '          <span class="hidden-xs"> 1</span>\n' +
    '        </button>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="btn-group btn-group-sm" style="margin: 7px 0;">\n' +
    '      <button ng-click="gameCtrl.buyout(player)" class="btn btn-primary" ng-disabled="!player.isPlaying" title="Buy out">\n' +
    '        <i class="icon icon-external-link"></i>\n' +
    '        <span class="hidden-xs"> Buy out</span>\n' +
    '      </button>\n' +
    '      <button ng-click="gameCtrl.bust(player)" class="btn btn-danger" ng-disabled="!player.isPlaying" title="Bust">\n' +
    '        <i class="icon icon-trash"></i>\n' +
    '        <span class="hidden-xs"> Bust</span>\n' +
    '      </button>\n' +
    '      <button ng-click="gameCtrl.comeBack(player)" class="btn btn-primary" ng-disabled="player.isPlaying" title="Come Back">Come Back</button>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
